import big, { BigSource } from 'big.js';
import {
	datadogRum,
	RumActionEventDomainContext,
	RumErrorEvent,
	RumErrorEventDomainContext,
	RumEvent
} from '@datadog/browser-rum';
import { denyUrls, ignoreErrors, isSuppressedStatusCode, processResponseData } from '@utils/error-tracking';
import { AxiosError } from 'axios';
import { InvestmentEntity } from 'types/investment-entity';
import { sanitizeNumbers } from '@filters/shared-filters';

export const setDatadogUserAUM = (accountValue: BigSource): void => {
	const value = big(accountValue).round();
	const aumBuckets = {
		'$0-1k': value.lte(1000),
		'$1k-5k': value.gt(1000) && value.lte(5000),
		'$5k-10k': value.gt(5000) && value.lte(10000),
		'$10k-25k': value.gt(10000) && value.lte(25000),
		'$25k-50k': value.gt(25000) && value.lte(50000),
		'$50k-100k': value.gt(50000) && value.lte(100000),
		'$100k-500k': value.gt(100000) && value.lte(500000),
		'$500k-1m': value.gt(500000) && value.lte(1000000),
		'$1m+': value.gt(1000000)
	};

	const aumBucket = Object.entries(aumBuckets).filter(([, val]) => val)[0][0];
	datadogRum.setUserProperty('aum', aumBucket);
};

export const setDatadogUserEntityData = (entity: InvestmentEntity): void => {
	datadogRum.setUserProperty('investmentEntityId', entity.investmentEntityId);
	datadogRum.setUserProperty('planType', entity.currentRiaPlanInvestmentStrategy);
	datadogRum.setUserProperty('accountType', entity.entityTypeLabel);
	datadogRum.setUserProperty('isAccredited', entity.isVerifiedAccredited);
	datadogRum.setUserProperty('hasInvestmentGoal', entity.investorGoalExists);
};

export const setDatadogAutoInvestSchedule = (autoInvestSchedule: boolean): void => {
	datadogRum.setUserProperty('autoInvestEnabled', autoInvestSchedule);
};

export const isActionElementClickable = (context: RumActionEventDomainContext): boolean => {
	const eventTarget = context.events?.[0].target as HTMLElement;
	const hasTabindex = !!eventTarget.getAttribute('tabindex');
	const hasCursorPointer = eventTarget.classList.contains('cursor-pointer');
	const isButtonOrLink = ['A', 'BUTTON'].includes(eventTarget.tagName);
	return isButtonOrLink || hasTabindex || hasCursorPointer;
};

export const handleDatadogActionBeforeSend = (event: RumEvent, context: RumActionEventDomainContext): boolean => {
	if (event.context?.googlePlacesActive) {
		datadogRum.removeGlobalContextProperty('googlePlacesActive');
		return false;
	}

	const isClickable = isActionElementClickable(context);

	if (!isClickable) {
		return false;
	}

	return true;
};

export const setAxiosErrorContext = (event: RumEvent, error: AxiosError) => {
	const context = { ...event.context };
	context.Axios = {
		url: error.response?.config?.url ? sanitizeNumbers(error.response?.config.url) : undefined,
		data: processResponseData(error.response?.data),
		status: error.response?.status
	};

	return context;
};

export const handleDatadogErrorBeforeSend = (event: RumErrorEvent, context: RumErrorEventDomainContext): boolean => {
	const { message, stack } = event.error;
	let urlIsDenied = false;
	let isSuppressedStatus = false;

	if (stack) {
		urlIsDenied = denyUrls.some((url) => {
			if (typeof url === 'string') {
				return stack.includes(url);
			}

			return url.test(stack);
		});
	}

	const messageIsIgnored = ignoreErrors.some((error) => {
		if (typeof error === 'string') {
			return message.includes(error);
		}

		return error.test(message);
	});

	if (event.error.isAxiosError) {
		isSuppressedStatus = isSuppressedStatusCode(context.error as AxiosError);
	}

	return !urlIsDenied && !messageIsIgnored && !isSuppressedStatus;
};

export const processDatadogErrorEvent = (event: RumErrorEvent, context: RumErrorEventDomainContext): RumEvent => {
	const axiosError = context.error as AxiosError;

	if (axiosError) {
		event.context = setAxiosErrorContext(event, axiosError);
		if (axiosError.response?.data?.errorDescription) {
			event.error.message = axiosError.response?.data?.errorDescription;
		}
	}

	return event;
};

export const getSessionReplayLink = (): string | undefined => {
	const sessionReplayLink = datadogRum.getSessionReplayLink();
	const invalidReplayLinkRegex = /no-session-id/i;
	const sessionReplayLinkIsValid = sessionReplayLink && !invalidReplayLinkRegex.test(sessionReplayLink);
	return sessionReplayLinkIsValid ? sessionReplayLink : undefined;
};
